.myFontWeight {
  font-weight: 400 ;
  color : red;
}
.myFontWeight:hover {
  font-weight: bolder !important;
  color : blue;
  background-color: blueviolet;
}

/* ::-webkit-scrollbar {
    width: 3px;
  } */
  
  /* Track */
  /* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } */
   
  /* Handle */
  /* ::-webkit-scrollbar-thumb {
    background: #F5F6FA; 
    border-radius: 10px;
  } */
  
  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #1C8AFF; 
  } */