.App {
  text-align: center;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* svg {
  margin-top: 50px;
} */
svg #letters path {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  fill-opacity: 0;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  border-right:0px !important;
  border:0px !important;
}
.css-1l8j5k8{
  border-right:0px !important;
  
}

.css-1awuyhc {
  color: #C9DDE3 !important;
}
.css-1gjpaj2-MuiSvgIcon-root-MuiStepIcon-root {
  color: #C9DDE3 !important;
}
.css-1gjpaj2-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #29C4F4 !important;
}
.css-1awuyhc.Mui-active{
  color: #29C4F4 !important;
}
.css-1gjpaj2-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: black !important;
}
.css-1awuyhc.Mui-completed{
  color: black !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
